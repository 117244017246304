<template>
	<div class="demo-drawer__content" style="width: 100%">
		<div
			class="nickName"
			@click="$router.push('/clientInfo?token=' + $route.query.shortLink)"
		>
			{{ $route.query.clientName }}
		</div>
		<div class="box-right">
			<div v-if="msgList.length" class="recvfrom">
				<div ref="scroll" class="news-top">
					<ul>
						<li
							v-for="(item, index) in msgList"
							:key="index"
							:class="[
								params.userId == item.addUserId
									? 'self'
									: 'other',
							]"
						>
							<div class="msg">
								<p class="msg-name">
									<span>{{
										item.followFeedbackPersonName
									}}</span>
									<span>{{
										moment(parseInt(item.addTime)).format(
											'YYYY年MM月DD HH:mm'
										)
									}}</span>
								</p>
								<p
									class="msg-text"
									v-html="item.followFeedbackContent"
								></p>
							</div>
							<div class="avatar">
								<img alt="" src="@/assets/t.png" />
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div v-else class="recvfrom">
				<p
					style="
						text-align: center;
						padding-top: 100px;
						color: #423e3e;
					"
				>
					暂无信息
				</p>
			</div>
			<div class="sendto">
				<div class="but-text">
					<el-input
						ref="Mytextarea"
						v-model="msg"
						type="textarea"
						name=""
						id=""
						:cols="110"
						:rows="5"
					></el-input>
					<el-button
						:loading="loading"
						type="primary"
						class="button"
						@click="postAddFollowFeedback"
						>发送</el-button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import {
	weChatQueryfollowFeedbackList,
	weChatAddFollowFeedback,
} from '@/api/index.js';
export default {
	name: 'mobileReply',
	data() {
		return { msg: '', msgList: [], loading: false, params: {} };
	},
	methods: {
		moment,
		async getFollowFeedbackList() {
			const res = await weChatQueryfollowFeedbackList(this.params);
			this.loading = false;
			let list = [];
			res.forEach((element) => {
				element.followFeedbackContent =
					element.followFeedbackContent.replace(/\n/g, '<br />');
				element.addTime = moment(element.addTime).valueOf();
				list.push(element);
				if (element.children.length > 0) {
					element.children.forEach((celement) => {
						celement.followFeedbackContent =
							celement.followFeedbackContent.replace(
								/\n/g,
								'<br />'
							);

						celement.addTime = moment(celement.addTime).valueOf();

						list.push(celement);
					});
				}
			});
			list = list.sort((a, b) => a.addTime - b.addTime);
			this.msgList = list;
			this.$nextTick(() => {
				try {
					let e = this.$refs.scroll;
					// this.$refs.Mytextarea.focus();
					e.scrollTop = e.scrollHeight;
				} catch {}
			});
		},
		async postAddFollowFeedback() {
			if (!this.msg.length)
				return this.$message({
					message: '发送内容不能为空',
					type: 'warning',
				});
			this.loading = true;
			const res = await weChatAddFollowFeedback({
				followFeedbackContent: this.msg,
				...this.params,
			});
			this.msg = '';
			this.getFollowFeedbackList();
		},
	},
	created() {
		this.params = this.$route.query;
		this.getFollowFeedbackList();
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}

::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}
::v-deep .el-drawer__header {
	margin-bottom: 0;
	padding: 15px;
}
::v-deep textarea {
	border: none;
	height: 165px;
	resize: none;
	box-sizing: border-box;
	padding: 20px;
}
.nickName {
	cursor: pointer;
	height: 50px;
	text-align: center;
	line-height: 50px;
	position: fixed;
	width: 100%;
	background: #fff;
	color: #2440b3;
	z-index: 2;
	font-weight: 800;
	font-size: 16px;
	box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}
.box-right {
	width: 100%;
	height: 100vh;
	overflow: hidden;
}
.box-right .recvfrom {
	width: 100%;
	height: calc(100% - 200px);
	background: #f1f1f1;
	// border-top: 1px solid #d3d1d1;
	border-bottom: 1px solid #d3d1d1;
}
.box-right .recvfrom .news-top {
	height: 100%;
	padding-top: 60px;
	overflow: auto;
}
.box-right .recvfrom .news-top ul {
	width: 100%;
	overflow: hidden;
}
.box-right .recvfrom .news-top ul li {
	display: flex;
	overflow: hidden;
	width: 100%;
	padding: 5px 20px;
	box-sizing: border-box;
}
.box-right .recvfrom .news-top ul li.other {
	flex-flow: row-reverse;
	justify-content: flex-end;
}
.box-right .recvfrom .news-top ul li.self {
	justify-content: flex-end;
}
.box-right .recvfrom .news-top ul li .avatar img {
	height: 30px;
	width: 30px;
	border-radius: 50%;
}
.box-right .recvfrom .news-top ul li .msg {
	padding: 8px;
	min-height: 80px;
	width: 100%;
	box-sizing: border-box;
}
.box-right .recvfrom .news-top ul li::after {
	clear: both;
	content: '';
	display: inline-block;
}
.box-right .recvfrom .news-top ul li .msg .msg-text {
	background-color: #fff;
	border-radius: 5px;
	padding: 8px 15px;
	line-height: 20px;
	position: relative;
	margin-top: 5px;
	word-wrap: break-word;
}
.box-right .recvfrom .news-top ul li.other .avatar {
	padding-top: 20px;
}
.box-right .recvfrom .news-top ul li.other .msg {
	left: 40px;
}
.box-right .recvfrom .news-top ul li.self .msg-name {
	width: 100%;
	text-align: right;
}
.box-right .recvfrom .news-top ul li .msg-name {
	color: #888383;
	font-size: 12px;
}
::v-deep textarea {
	height: 80% !important;
}
.box-right .recvfrom .news-top ul li .msg-name span {
	margin: 0 5px;
}
.box-right .recvfrom .news-top ul li.self .msg-name {
	width: 100%;

	display: flex;
	flex-flow: row-reverse;
}
.box-right .recvfrom .news-top ul li.self .avatar {
	padding-top: 20px;
}
.box-right .recvfrom .news-top ul li.self .msg {
	right: 38px;
}
.box-right .recvfrom .news-top ul li.self .msg-text {
	background: #b1dfff;
	max-width: 80% !important;
	float: right;
}
.box-right .recvfrom .news-top ul li.other .msg-text {
	max-width: 80% !important;
	float: left;
}
.box-right .sendto {
	width: 100%;
	height: 200px;
	overflow: hidden;
	position: relative;
}
.box-right .sendto .but-text {
	height: 100%;
}
.el-textarea {
	height: 100%;
}
.box-right .sendto .but-text .el-input {
	border: none !important;
	font-size: 22px;
	margin-left: 20px;
	width: 100%;
	height: 150px;
}
.box-right .sendto .but-text .button {
	float: right;
	padding: 7px 45px;
	background-color: #3bb4f2;
	margin-right: 10px;
	position: absolute;
	right: 0;
	bottom: 10px;
}

.other .msg-text:before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	left: -10px;
	border: 5px solid transparent;
	border-right-color: #fff;
}
.self .msg-text:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	right: -10px;
	top: 10px;
	border: 5px solid transparent;
	border-right-color: #b1dfff;
	transform: rotate(180deg);
}
</style>
